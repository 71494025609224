// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacta-js": () => import("./../src/pages/contacta.js" /* webpackChunkName: "component---src-pages-contacta-js" */),
  "component---src-pages-equipo-especialistas-drupal-js": () => import("./../src/pages/equipo-especialistas-drupal.js" /* webpackChunkName: "component---src-pages-equipo-especialistas-drupal-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-proyectos-drupal-js": () => import("./../src/pages/proyectos-drupal.js" /* webpackChunkName: "component---src-pages-proyectos-drupal-js" */),
  "component---src-pages-proyectos-chalalai-js": () => import("./../src/pages/proyectos/chalalai.js" /* webpackChunkName: "component---src-pages-proyectos-chalalai-js" */),
  "component---src-pages-proyectos-dimarzio-drupal-8-js": () => import("./../src/pages/proyectos/dimarzio-drupal-8.js" /* webpackChunkName: "component---src-pages-proyectos-dimarzio-drupal-8-js" */),
  "component---src-pages-proyectos-eaglemoss-drupal-js": () => import("./../src/pages/proyectos/eaglemoss-drupal.js" /* webpackChunkName: "component---src-pages-proyectos-eaglemoss-drupal-js" */),
  "component---src-pages-proyectos-helse-drupal-8-js": () => import("./../src/pages/proyectos/helse-drupal8.js" /* webpackChunkName: "component---src-pages-proyectos-helse-drupal-8-js" */),
  "component---src-pages-proyectos-mohawk-drupal-8-agencia-js": () => import("./../src/pages/proyectos/mohawk-drupal-8-agencia.js" /* webpackChunkName: "component---src-pages-proyectos-mohawk-drupal-8-agencia-js" */),
  "component---src-pages-proyectos-tenias-drupal-8-js": () => import("./../src/pages/proyectos/tenias-drupal8.js" /* webpackChunkName: "component---src-pages-proyectos-tenias-drupal-8-js" */),
  "component---src-pages-servicios-drupal-js": () => import("./../src/pages/servicios-drupal.js" /* webpackChunkName: "component---src-pages-servicios-drupal-js" */),
  "component---src-pages-servicios-consultoria-drupal-js": () => import("./../src/pages/servicios/consultoria-drupal.js" /* webpackChunkName: "component---src-pages-servicios-consultoria-drupal-js" */),
  "component---src-pages-servicios-desarrollo-drupal-a-medida-js": () => import("./../src/pages/servicios/desarrollo-drupal-a-medida.js" /* webpackChunkName: "component---src-pages-servicios-desarrollo-drupal-a-medida-js" */),
  "component---src-pages-servicios-diseno-ux-js": () => import("./../src/pages/servicios/diseno-ux.js" /* webpackChunkName: "component---src-pages-servicios-diseno-ux-js" */),
  "component---src-pages-servicios-formacion-drupal-js": () => import("./../src/pages/servicios/formacion-drupal.js" /* webpackChunkName: "component---src-pages-servicios-formacion-drupal-js" */),
  "component---src-pages-servicios-mantenimiento-hosting-drupal-js": () => import("./../src/pages/servicios/mantenimiento-hosting-drupal.js" /* webpackChunkName: "component---src-pages-servicios-mantenimiento-hosting-drupal-js" */),
  "component---src-pages-servicios-migraciones-drupal-8-js": () => import("./../src/pages/servicios/migraciones-drupal-8.js" /* webpackChunkName: "component---src-pages-servicios-migraciones-drupal-8-js" */),
  "component---src-pages-servicios-seo-sem-js": () => import("./../src/pages/servicios/seo-sem.js" /* webpackChunkName: "component---src-pages-servicios-seo-sem-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../src/pages/trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */)
}

